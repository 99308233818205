import React from 'react';

import './index.css';
import workDots from '../../../img/work-dots.svg';
import dotsRight from '../../../img/dots-hero-right.svg';
import hero from '../../../img/product-management-hero@2x.png';

export default () => {
  return (
    <section className="product-hero">
      <span className="circle" />
      <img
        className="center-dots"
        src={workDots}
        alt="Dots"
      />
      <img
        className="left-dots"
        src={workDots}
        alt="Dots"
      />
      <img
        className="right-dots"
        src={dotsRight}
        alt="Dots"
      />
      <div className="top root-padding">
        <div className="left">
          <h1 className="c-title">Product Management</h1>
          <p className="subtitle">
            Accelerate your product roadmap. Get to market faster than ever
            before. Celebrate.
          </p>
        </div>
        <img
          className="right"
          src={hero}
          alt="Hero"
        />
      </div>
      <div className="bottom secondary-padding">
        <p>
          Devetry’s product management team can help you prioritize, build, and
          deploy software updates in a strategic, user-first way. Our blended
          experience of business and technology gives your digital product the
          edge it needs to compete in today’s winner-take-all world. Whether you
          need an end-to-end solution, interim manager, or additional technical
          resources, our product management team would love to help you succeed!
        </p>
      </div>
    </section>
  );
};
