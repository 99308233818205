import React, { useRef } from 'react';
import { useCompleteOnScreen } from '../../../utils/hooks';

import { AnimatedDots } from '../../Dots';
import './index.css';
import workDots from '../../../img/work-dots.svg';

export default () => {
  const ref = useRef();
  const completeOnScreen = useCompleteOnScreen(ref);
  return (
    <section ref={ref} className="product-second secondary-padding">
      <img
        className="right-dots"
        src={workDots}
        alt="Dots"
      />
      <p className="c-title">How we craft your product strategy</p>
      <AnimatedDots completeOnScreen={completeOnScreen} />
      <p className="subtitle">
        Getting your digital product to market while minimizing costs and
        maximizing value is what we do. Our product expertise and deep technical
        aptitude provide you an accelerated path to define your product roadmap
        and implement a phased, test-driven plan for your market.
      </p>
      <div className="items">
        <ul>
          <li>
            <p>Problem statement clarity</p>
          </li>
        </ul>
        
        <ul>
          <li>
            <p>Product-market fit</p>
          </li>
        </ul>

        <ul>
          <li> 
            <p>User personas</p>
          </li>
        </ul>

        <ul>
          <li>
            <p>User requirements gathering</p>
          </li>
        </ul>

        <ul>
          <li>
            <p>Competitive analysis</p>
          </li>
        </ul>

        <ul>
          <li>
            <p>Go to market strategy</p>
          </li>
        </ul>

        <ul>
          <li>
            <p>Roadmap planning </p>
          </li>
        </ul>

        <ul>
          <li>
            <p>Phased release planning</p>
          </li>
        </ul>

        <ul>
          <li>
            <p>Lean minimum viable product and prototyping</p>
          </li>
        </ul>

        <ul>
          <li>
            <p>End-user testing</p>
          </li>
        </ul>

        <ul>
          <li>
            <p>Iterations and release plan</p>
          </li>
        </ul>
      </div>
    </section>
  );
};
