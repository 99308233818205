import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';
import quoteImage from '../../img/testimonial-ricker@2x.png';
import './index.css';

// Sections
import Hero from '../../components/productSections/Hero';
import Second from '../../components/productSections/Second';
import Third from '../../components/productSections/Third';
import Quote from '../../components/Quote';

const pageTitle =
  'Digital Product Management | We Extend Your Product Team | Devetry';
const pageDescription =
  'We can be your product team or extend your product team. Accelerate your roadmap with digital product management services from Devetry.';

const ProductPageTemplate = ({ blogs }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('services');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <div className="root-container product">
        <Hero />
        <Second />
        <Third />
        <Quote
          title="Boost Your Product Knowledge"
          blogs={blogs}
          name="Kathrine Ricker"
          image={quoteImage}
          position="me3 | ASU"
          quote="“Devetry is amazing to work with! They are solutions-focused and use an innovative approach to problem-solving, all while working closely with the ASU team and incorporating feedback in creating an amazing tool. Very happy to have the partnership and look forward to building upon our shared accomplishments!”"
        />
      </div>
    </>
  );
};

const ProductPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark;
  const productBlogs = edges.map((e) => ({
    readingTime: e.node.fields.readingTime.text,
    slug: e.node.fields.slug,
    ...e.node.frontmatter
  }));

  return <ProductPageTemplate blogs={productBlogs} />;
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default ProductPage;

export const pageQuery = graphql`
  query ProductPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Product/index" } }) {
      frontmatter {
        templateKey
      }
    }
    allMarkdownRemark(
      filter: {
        published: { eq: true },
        frontmatter: {
          templateKey: { eq: "Blog/index" }
          category: { eq: "Product" }
        }
      }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
  }
`;
