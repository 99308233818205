import React from 'react';

import './index.css';
import workDots from '../../../img/work-dots.svg';
import puzzle from '../../../img/puzzle.svg';
import orangeEllipse from '../../../img/orange-ellipse.svg';

export default () => {
  return (
    <section className="product-third">
      <div className="center secondary-padding">
        <img
          className="center-dots"
          src={workDots}
          alt="Dots"
        />
        <div className="blog-left">
          <p className="c-title">Process Optimization</p>
          <p className="subtitle">
            We are an agile development company, but we believe in processes that 
            work for you. Whether this is truly agile, hybrid-agile, or “watergile”, 
            we’re happy to make it work across our team. 
            <br />
            <br /> 
            Need help implementing agile or refining your process? We do that too. 
          </p>
          <p className="c-title">Product Roadmap Development</p>
          <p className="subtitle">
            Creating a product roadmap is a bit art, a bit science, a lot 
            ROI-driving factors. Getting your roadmap right allows you to delight 
            users and impress dinternal stakeholders. 
            <br/>
            <br/>
            Devetry can help you analyze your product, find low-risk/high-reward 
            initiatives, incrementally test and iterate, and prove ROI. 
          </p>
        </div>
        <img
          className="puzzle"
          src={puzzle}
          alt="Puzzle"
        />
      </div>
      <div className="top secondary-padding">
        <img
          className="orange-ellipse"
          src={orangeEllipse}
        />
        <img
          className="first-dots"
          src={workDots}
          alt="Dots"
        />
        <img
          className="second-dots"
          src={workDots}
          alt="Dots"
        />
        <div className="red-dot" />
        <div className="blog-left">
          <p className="c-title">User Workshops</p>
          <p className="subtitle">
            A significant part of a successful product is a strategic UX/UI
            design, based on real users and data. To acquire this research in a
            cost-effective way, we recommend product design workshops.
            <br />
            <br /> 
            These half-day or full-day design workshops include research,
            interviews, surveys, and focus groups to identify core motivations,
            friction, and product preferences. The end result? A documented
            UX/UI strategy for your digital product.
          </p>
          <p className="c-title">Product Scale & Growth</p>
          <p className="subtitle">
            If you’ve launched a successful minimum viable product (MVP), 
            then you may be asking yourself, “where do we go from here?” 
            <br/>
            <br/>
            We can help. 
            <br/>
            <br/>
            Devetry specializes in next-stage growth and scaling to transform 
            your product into its full potential.
          </p>
        </div>
      </div>
    </section>
  );
};
